import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Back, gsap, Expo, Elastic, Power0, Power3 } from 'gsap';

@Component({
    selector: 'webcoffee-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class LoadingComponent implements OnInit, OnDestroy {
    @Input() message!: string;

    timer1!: any;
    timer2!: any;

    ngOnInit() {
        if (this.message) {
            const dots = document.querySelector('.dots');
            this.animate(dots, 'dots--animate');
        }
        this.coffeeAnimate();
    }

    animate(element: any, className: string) {
        element.classList.add(className);
        this.timer1 = setTimeout(() => {
            element.classList.remove(className);
            this.timer2 = setTimeout(() => {
                this.animate(element, className);
            }, 500);
        }, 2500);
    }

    ngOnDestroy() {
        clearTimeout(this.timer1);
        clearTimeout(this.timer2);
    }

    coffeeAnimate() {
        const mainTl = gsap.timeline({ repeat: -1 });

        mainTl
            .set(document.getElementById('coffeePackLogo'), { transformOrigin: '50% 50%' })
            // .set([document.getElementById('coffeePackArc'), document.getElementById('coffeePowder'), document.getElementById('smoke'), document.getElementById('coffeeLastPour')], {
            //     drawSVG: false,
            // })
            .from(document.getElementById('coffeePackTop'), { duration: 1, x: -250 })
            .from(document.getElementById('coffeePackBody'), { duration: 0.7, x: 250 }, '-=0.7')
            .from([document.getElementById('coffeePackLineTop'), document.getElementById('coffeePackBottom')], { duration: 0.7, x: -250 }, '-=0.7')
            .from(document.getElementById('coffeePackLineMiddle'), { duration: 0.7, x: -250 }, '-=0.6')
            .from(document.getElementById('coffeePackLineBottom'), { duration: 0.7, x: -250 }, '-=0.5')
            .from(document.getElementById('coffeePackLogo'), { duration: 0.3, scale: 0 }, '-=0.5')
            // .to(document.getElementById('coffeePackArc'), { duration: 0.3, drawSVG: '0% 100%' }, '-=0.5')
            .set(document.getElementById('coffeePack'), { transformOrigin: '50% 30%' })
            .to(document.getElementById('coffeePack'), { duration: 1, rotation: -90, x: '+=50', y: '-=5', ease: Expo.easeInOut })
            .from(document.getElementById('coffeeFilter'), { duration: 1, y: 300 }, '-=1.1')
            // .fromTo(document.getElementById('coffeePowder'), { duration: 0.8, drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Expo.easeOut }, '-=0.3')
            // .to(document.getElementById('coffeePowder'), { duration: 0.5, drawSVG: '0% 0%', ease: Expo.easeOut })
            .to(document.getElementById('coffeePack'), { duration: 0.5, x: '+=300', ease: Back.easeIn }, '-=0.5')
            .to(document.getElementById('coffeeFilter'), { duration: 1, y: '-=130', ease: Expo.easeOut })
            .from(document.getElementById('coffeeFilterCover'), { duration: 0.3, y: 300 }, '-=0.8')
            .from(document.getElementById('coffeeFilterCoverTopLid'), { duration: 0.3, y: -100 }, '-=0.8')
            .set(document.getElementById('coffeeFilter'), { opacity: 0 }, '-=0.53')
            .to(document.getElementById('scene3'), { duration: 1, x: '+=15' })
            //here comes scene 4!
            .from(document.getElementById('waterBottle'), { duration: 1, x: -300 }, '-=0.8')
            .from(document.getElementById('coffeeMachineGrayTop'), { duration: 0.7, x: 300 }, '-=0.8')
            .from(document.getElementById('coffeeMachineFilterSupport'), { duration: 0.7, x: 300 }, '-=0.6')
            .from(document.getElementById('coffeeMachineWaterBottleBottom'), { duration: 0.7, x: -300 }, '-=0.8')
            .from(document.getElementById('coffeeBottle'), { duration: 0.7, x: 300 }, '-=0.6')
            .from(document.getElementById('water'), { duration: 1, rotation: -10, y: '+=10', ease: Elastic.easeOut })
            .from(document.getElementById('coffeeMachineBody'), { duration: 0.8, x: -300 }, '-=1.5')
            .from(document.getElementById('coffeeMachineBodyBottom'), { duration: 0.8, x: -300 }, '-=1.5')
            .from(document.getElementById('coffeeMachineCoffeeBottleBottom'), { duration: 0.8, x: 300 }, '-=1.5')
            //console
            .from(document.getElementById('coffeeMachineConsoleEarR'), { duration: 0.9, x: 300 }, '-=1.5')
            .from(document.getElementById('coffeeMachineConsoleButton2'), { duration: 0.9, x: -300 }, '-=1.5')
            .from(document.getElementById('coffeeMachineConsoleButton1'), { duration: 0.9, x: -300 }, '-=1.55')
            .from(document.getElementById('coffeeMachineConsoleFront'), { duration: 0.8, x: -300 }, '-=1.5')
            .from(document.getElementById('coffeeMachineConsoleBack'), { duration: 0.8, x: -300 }, '-=1.5')
            .from(document.getElementById('coffeeMachineConsoleEarL'), { duration: 0.8, x: -300 }, '-=1.5')
            .from(document.getElementById('coffeeMachineConsoleFootL'), { duration: 0.6, x: -300 }, '-=1.2')
            .from(document.getElementById('coffeeMachineConsoleFootR'), { duration: 0.6, x: 300 }, '-=1.2')
            .from(document.getElementById('shadow5'), { duration: 0.6, x: 350 }, '-=1')
            .from(document.getElementById('shadow4'), { duration: 0.6, x: -350 }, '-=1')
            .from(document.getElementById('shadow3'), { duration: 0.6, x: 350 }, '-=1')
            .from(document.getElementById('shadow2'), { duration: 0.8, x: 350 }, '-=1')
            .from(document.getElementById('shadow1'), { duration: 1, x: 350 }, '-=1')
            // .set(document.getElementById('coffeeDistilled'), { drawSVG: false })
            .to(document.getElementById('water'), { duration: 3, y: '+=50', ease: Power0.easeNone })
            // .fromTo(document.getElementById('coffeeDistilled'), { duration: 0.5, drawSVG: '5% 5%' }, { drawSVG: '5% 100%', ease: Power0.easeNone }, '-=3')
            .from(document.getElementById('coffeeWater'), { duration: 2, y: '+=50', ease: Power0.easeNone }, '-=3.5')
            // .to(document.getElementById('coffeeDistilled'), { duration: 0.3, drawSVG: '99% 100%', ease: Power0.easeNone }, '-=1.5')
            //everybody GET OUT!
            .to(document.getElementById('waterBottle'), { duration: 0.6, x: -300, ease: Power0.easeIn })
            .to(document.getElementById('scene3'), { duration: 0.6, x: 300, ease: Power0.easeIn }, '-=0.6')
            .to(document.getElementById('coffeeMachineGrayTop'), { duration: 0.6, x: 300, ease: Power3.easeIn }, '-=0.9')
            .to(document.getElementById('coffeeMachineFilterSupport'), { duration: 0.7, x: 300, ease: Power3.easeIn }, '-=0.9')
            .to(document.getElementById('coffeeMachineWaterBottleBottom'), { duration: 0.7, x: -300, ease: Power3.easeIn }, '-=0.8')
            .to(document.getElementById('coffeeMachineBody'), { duration: 1, x: -300, ease: Power3.easeIn }, '-=0.8')
            .to(document.getElementById('coffeeMachineBodyBottom'), { duration: 0.8, x: -300 }, '-=1.2')
            .to(document.getElementById('coffeeMachineCoffeeBottleBottom'), { duration: 0.8, x: 300 }, '-=1')
            .to(document.getElementById('coffeeMachineConsoleEarR'), { duration: 0.9, x: 300 }, '-=1.1')
            .to(document.getElementById('coffeeMachineConsoleButton2'), { duration: 0.9, x: -300 }, '-=1.1')
            .to(document.getElementById('coffeeMachineConsoleButton1'), { duration: 0.9, x: -300 }, '-=1.1')
            .to(document.getElementById('coffeeMachineConsoleFront'), { duration: 0.8, x: -300 }, '-=1')
            .to(document.getElementById('coffeeMachineConsoleBack'), { duration: 0.8, x: -300 }, '-=1')
            .to(document.getElementById('coffeeMachineConsoleEarL'), { duration: 0.8, x: -300 }, '-=1')
            .to(document.getElementById('coffeeMachineConsoleFootL'), { duration: 0.6, x: -300 }, '-=0.8')
            .to(document.getElementById('coffeeMachineConsoleFootR'), { duration: 0.6, x: 300 }, '-=0.8')
            .to(document.getElementById('shadow5'), { duration: 0.6, x: 350 }, '-=1.6')
            .to(document.getElementById('shadow4'), { duration: 0.6, x: -350 }, '-=1.5')
            .to(document.getElementById('shadow3'), { duration: 0.6, x: 350 }, '-=1.4')
            .to(document.getElementById('shadow2'), { duration: 0.8, x: 350 }, '-=1.3')
            .to(document.getElementById('shadow1'), { duration: 1, x: 350 }, '-=1.2')
            //scene 5!
            .from(document.getElementById('cupOfCoffee'), { duration: 1, y: 300 })
            .to(document.getElementById('coffeeBottle'), { duration: 0.7, x: '+=30', y: '-=35', rotation: -45 }, '-=0.8')
            .to(document.getElementById('coffeeWater'), { duration: 0.7, rotation: 45, y: '-=70' }, '-=0.7')

            // .fromTo(document.getElementById('coffeeLastPour'), { duration: 0.8, drawSVG: '0% 0%' }, { drawSVG: '5% 100%', ease: Power0.easeNone }, '-=0.1')
            .to(document.getElementById('coffeeWater'), { duration: 1, y: '+=10', ease: Power0.easeNone }, '-=0.5')
            // .to(document.getElementById('coffeeLastPour'), { duration: 0.8, drawSVG: '99% 100%', ease: Power0.easeNone })
            .to(document.getElementById('coffeeBottle'), { duration: 0.6, y: '-=200', ease: Back.easeIn }, '-=0.3')
            // .fromTo(document.getElementById('smoke'), { duration: 1.2, drawSVG: '100%, 100%' }, { drawSVG: '0%, 100%', ease: Power2.easeOut }, '-=0.1')
            // .to(document.getElementById('smoke'), { duration: 1.2, drawSVG: '0%', ease: Power2.easeIn })
            // .set(document.getElementById('smoke'), { drawSVG: '0%' })
            .to(document.getElementById('cupOfCoffee'), { duration: 1, y: '-=300', ease: Back.easeIn });

        gsap.globalTimeline.timeScale(1.5);
    }
}
