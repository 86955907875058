<svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    width="40rem"
    height="30rem"
    viewBox="0 0 400 300"
    enable-background="new 0 0 400 300"
    xml:space="preserve">
    <g id="coffeePack">
        <rect id="coffeePackBody" x="173" y="88" fill="#5D2420" width="55" height="119" />
        <rect id="coffeePackTop" x="173" y="95" fill="#7C413C" width="55" height="25" />
        <rect id="coffeePackLineTop" x="180" y="175" fill="#C6996B" width="41" height="7" />
        <rect id="coffeePackLineMiddle" x="180" y="185" fill="#C6996B" width="41" height="7" />
        <rect id="coffeePackLineBottom" x="180" y="195" fill="#C6996B" width="41" height="7" />
        <path id="coffeePackBottom" fill="#331710" d="M173,207v5c0,2.209,1.791,4,4,4h47c2.209,0,4-1.791,4-4v-5H173z" />
        <g id="coffeePackLogo">
            <circle id="coffeeLogoCircle" fill="#AA7951" cx="200.5" cy="159" r="12.5" />
            <g id="coffeeSymbol">
                <path
                    id="coffeeSymbo2"
                    fill="#382113"
                    d="M194.374,163.334c-1.786-1.766-0.73-5.412,2.23-8.229
          c2.818-2.962,6.455-4.008,8.23-2.232c0.667,0.667-1.8,2.75-4.809,5.654C197.122,161.535,195.041,164.001,194.374,163.334z" />
                <path
                    id="coffeeSymbol1"
                    fill="#382113"
                    d="M206.627,154.666c1.786,1.767,0.73,5.412-2.23,8.229
          c-2.818,2.962-6.454,4.008-8.23,2.232c-0.667-0.667,1.8-2.75,4.808-5.653C203.879,156.465,205.96,153.999,206.627,154.666z" />
            </g>
        </g>
    </g>
    <g id="coffeeFilter">
        <circle id="coffeeFilterHandle" fill="#AA7951" cx="221.816" cy="187.704" r="5.704" />
        <path
            id="coffeeFilterBody"
            fill="#C79E71"
            d="M243.312,197.675c-15.685-13.933-43.519-13.932-43.519-13.932
			s-27.709-0.001-43.393,13.932c-5.645,5.014-3.137,9.453-0.523,13.434c0,0,28.571,33.519,30.23,35.628
			c2.171,2.174,2.708,4.264,9.5,4.264c1.956,0,6.667,0,8.623,0c5.918,0,7.453-2.473,9.468-4.264
			c1.609-1.941,30.199-35.73,30.199-35.73C246.513,207.025,248.956,202.688,243.312,197.675z" />

        <line
            id="coffeeFilterStroke5"
            fill="none"
            stroke="#AA7951"
            stroke-width="3"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="212.864"
            y1="242.24"
            x2="208.528"
            y2="247.386" />

        <line
            id="coffeeFilterStroke4"
            fill="none"
            stroke="#AA7951"
            stroke-width="3"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="206.864"
            y1="242.24"
            x2="202.528"
            y2="247.386" />

        <line
            id="coffeeFilterStroke3"
            fill="none"
            stroke="#AA7951"
            stroke-width="3"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="200.864"
            y1="242.24"
            x2="196.528"
            y2="247.386" />

        <line
            id="coffeeFilterStroke2"
            fill="none"
            stroke="#AA7951"
            stroke-width="3"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="194.864"
            y1="242.24"
            x2="190.528"
            y2="247.386" />

        <line
            id="coffeeFilterStroke1"
            fill="none"
            stroke="#AA7951"
            stroke-width="3"
            stroke-linecap="round"
            stroke-miterlimit="10"
            x1="188.864"
            y1="242.24"
            x2="187.294"
            y2="244.103" />
    </g>

    <g id="scene3">
        <path id="coffeeFilterCoverTopLid" fill="#282120" d="M240,61c0,0.552-0.447,1-1,1h-77c-0.552,0-1-0.448-1-1v-9c0-0.552,0.448-1,1-1h77c0.553,0,1,0.448,1,1V61z" />
        <g id="coffeeFilterCover">
            <path
                id="coffeeFilterCoverBody"
                fill="#1C1818"
                d="M254.5,67c-0.171,0-0.339,0.018-0.5,0.05V67h-16.267L240,61h-79l18,45
        c0,0,8.138,7,22.61,7s21.39-7,21.39-7l10.2-27H252v20.5c0,1.381,1.119,2.5,2.5,2.5s2.5-1.119,2.5-2.5v-30C257,68.119,255.881,67,254.5,67z" />
            <path
                id="coffeeFilterCoverBase"
                fill="#282120"
                d="M222,85h-4v14c0,3.866-3.134,7-7,7h-20c-3.866,0-7-3.134-7-7V85h-4
        c-0.552,0-1,0.448-1,1v35c0,0.553,0.448,1,1,1h42c0.553,0,1-0.447,1-1V86C223,85.448,222.553,85,222,85z" />
        </g>
    </g>

    <g id="scene4">
        <path
            id="shadow5"
            fill="#AA9A8A"
            d="M288,256.25c0,3.176-2.574,5.75-5.75,5.75H83.75c-3.176,0-5.75-2.574-5.75-5.75v-0.5
      c0-3.176,2.574-5.75,5.75-5.75h198.5c3.176,0,5.75,2.574,5.75,5.75V256.25z" />
        <path
            id="shadow4"
            fill="#AA9A8A"
            d="M251,275.25c0,3.176-2.574,5.75-5.75,5.75h-82.5c-3.176,0-5.75-2.574-5.75-5.75v-0.5
      c0-3.176,2.574-5.75,5.75-5.75h82.5c3.176,0,5.75,2.574,5.75,5.75V275.25z" />
        <path
            id="shadow3"
            fill="#AA9A8A"
            d="M301,275.25c0,3.176-2.574,5.75-5.75,5.75h-31.5c-3.176,0-5.75-2.574-5.75-5.75v-0.5
      c0-3.176,2.574-5.75,5.75-5.75h31.5c3.176,0,5.75,2.574,5.75,5.75V275.25z" />
        <path
            id="shadow2"
            fill="#AA9A8A"
            d="M323,275.25c0,3.176-2.574,5.75-5.75,5.75h-5.5c-3.176,0-5.75-2.574-5.75-5.75v-0.5
      c0-3.176,2.574-5.75,5.75-5.75h5.5c3.176,0,5.75,2.574,5.75,5.75V275.25z" />
        <path
            id="shadow1"
            fill="#AA9A8A"
            d="M288,293.25c0,3.176-2.574,5.75-5.75,5.75h-62.5c-3.176,0-5.75-2.574-5.75-5.75v-0.5
      c0-3.176,2.574-5.75,5.75-5.75h62.5c3.176,0,5.75,2.574,5.75,5.75V293.25z" />
        <rect id="coffeeMachineConsoleBack" x="95" y="222" fill="#8E867D" width="176" height="25" />
        <path
            id="coffeeMachineConsoleFront"
            fill="#CFCFCF"
            d="M265,234.5c0-4.143-3.357-7.5-7.5-7.5h-150c-4.142,0-7.5,3.357-7.5,7.5l0,0
      c0,4.143,3.358,7.5,7.5,7.5h150C261.643,242,265,238.643,265,234.5L265,234.5z" />
        <path
            id="coffeeMachineGrayTop"
            fill="#A7AAAC"
            d="M221,48.25c0-1.795-1.455-3.25-3.25-3.25h-61.5c-1.795,0-3.25,1.455-3.25,3.25
      v0.5c0,1.795,1.455,3.25,3.25,3.25h61.5c1.795,0,3.25-1.455,3.25-3.25V48.25z" />
        <path
            id="coffeeMachineFilterSupport"
            fill="#1C1818"
            d="M251,126c0,1.657-1.343,3-3,3h-79c-1.657,0-3-1.343-3-3v-4
      c0-1.657,1.343-3,3-3h79c1.657,0,3,1.343,3,3V126z" />
        <g id="waterBottle">
            <path
                id="waterBottlelBg"
                fill="#E7EBEB"
                d="M106,104c-1.654,0-5-3.346-5-5v-0.212l-4.995-46.956C96.092,50.255,97.402,49,99,49
        h67c1.598,0,2.908,1.255,2.995,2.832L164,98.788V99c0,1.654-3.346,5-5,5H106z" />
            <g>
                <defs>
                    <clipPath id="waterMask">
                        <path
                            id="waterMaskPath"
                            d="M106,102c-1.654,0-3-1.346-3-3v-0.212l-4.995-46.956C98.092,50.255,99.402,49,101,49h63c1.598,0,2.908,1.255,2.995,2.832L162,98.788V99c0,1.654-1.346,3-3,3H106z" />
                    </clipPath>
                    <clipPath id="coffeeBottleGlassBgMask">
                        <path
                            id="coffeeBottleGlassBgMaskPath"
                            d="M248,150h-62c-2.761,0-5.167,0-8-5l-2.25-4.5c-2.083-4.417,1.489-4.5,4.25-4.5h68V150z
               M253,202c0,4.971-4.029,9-9,9h-59c-4.971,0-9-4.029-9-9v-35c0-4.971,4.029-9,9-9h59c4.971,0,9,4.029,9,9V202z" />
                    </clipPath>
                </defs>
            </g>
            <g clip-path="url(#waterMask)">
                <rect id="water" x="85" y="78" fill="#258AB1" width="95" height="39" />
            </g>
            <path
                fill="#FFFFFF"
                d="M164,45h-63c-3.866,0-7,3.134-7,7l5,47c0,3.866,3.134,7,7,7h53c3.866,0,7-3.134,7-7l5-47
        C171,48.134,167.866,45,164,45z M162,98.788V99c0,1.654-1.346,3-3,3h-53c-1.654,0-3-1.346-3-3v-0.212l-4.995-46.956
        C98.092,50.255,99.402,49,101,49h63c1.598,0,2.908,1.255,2.995,2.832L162,98.788z" />
            <path
                fill="#282120"
                d="M166,52c0,1.657-1.343,3-3,3h-60c-1.657,0-3-1.343-3-3l2-15c0-1.657,1.343-3,3-3h56c1.657,0,3,1.343,3,3
        L166,52z" />
            <path
                fill="#1C1818"
                d="M173,48.75c0,1.795-1.455,3.25-3.25,3.25h-74.5C93.455,52,92,50.545,92,48.75v-0.5
        c0-1.795,1.455-3.25,3.25-3.25h74.5c1.795,0,3.25,1.455,3.25,3.25V48.75z" />

            <line fill="none" stroke="#340904" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="124" y1="63" x2="140" y2="63" />

            <line fill="none" stroke="#340904" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="124" y1="73" x2="140" y2="73" />

            <line fill="none" stroke="#340904" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="124" y1="83" x2="140" y2="83" />

            <line fill="none" stroke="#340904" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" x1="124" y1="93" x2="140" y2="93" />
            <path
                opacity="0.7"
                fill="#FFFFFF"
                d="M111.424,91.414c-3.075,0-5.693-2.351-5.97-5.472l-2.308-26.077
        c-0.292-3.301,2.147-6.213,5.448-6.505c3.297-0.293,6.213,2.147,6.505,5.448l2.308,26.077c0.292,3.301-2.147,6.213-5.448,6.505
        C111.78,91.406,111.601,91.414,111.424,91.414z" />
        </g>
        <path
            id="coffeeMachineWaterBottleBottom"
            fill="#1C1818"
            d="M160,111.5c0,1.933-1.567,3.5-3.5,3.5h-50c-1.933,0-3.5-1.567-3.5-3.5
      l0,0c0-1.933,1.567-3.5,3.5-3.5h50C158.433,108,160,109.567,160,111.5L160,111.5z" />
        <g id="coffeeMachineBody">
            <path
                fill="#8E867D"
                d="M160,210c0,1.104-0.896,2-2,2h-53c-1.104,0-2-0.896-2-2v-91c0-1.104,0.896-2,2-2h53c1.104,0,2,0.896,2,2
        V210z" />
            <path
                fill="#CFCFCF"
                d="M152,199c0,3.866-3.134,7-7,7h-3c-3.866,0-7-3.134-7-7v-70c0-3.866,3.134-7,7-7h3c3.866,0,7,3.134,7,7V199
        z" />
            <path
                fill="#CFCFCF"
                d="M128,178c0,3.866-3.134,7-7,7h-3c-3.866,0-7-3.134-7-7v-49c0-3.866,3.134-7,7-7h3c3.866,0,7,3.134,7,7V178
        z" />
            <circle fill="#CFCFCF" cx="119.5" cy="197.5" r="8.5" />
        </g>
        <path
            id="coffeeMachineBodyBottom"
            fill="#1C1818"
            d="M160,218c0-1.657-1.343-3-3-3h-51c-1.657,0-3,1.343-3,3v1
      c0,1.657,1.343,3,3,3h51c1.657,0,3-1.343,3-3V218z" />
        <path
            id="coffeeMachineCoffeeBottleBottom"
            fill="#1C1818"
            d="M255,218c0-1.657-1.344-3-3-3h-73c-1.657,0-3,1.343-3,3v1
      c0,1.657,1.343,3,3,3h73c1.656,0,3-1.343,3-3V218z" />
        <g id="coffeeBottle">
            <g id="coffeeBottleGlassBg">
                <path fill="#DBDFE3" d="M248,150h-62c-2.761,0-5.167,0-8-5l-2.25-4.5c-2.083-4.417,1.489-4.5,4.25-4.5h68V150z" />
                <path
                    fill="#DBDFE3"
                    d="M253,202c0,4.971-4.029,9-9,9h-59c-4.971,0-9-4.029-9-9v-35c0-4.971,4.029-9,9-9h59c4.971,0,9,4.029,9,9
          V202z" />
            </g>
            <g clip-path="url(#coffeeBottleGlassBgMask)">
                <rect id="coffeeWater" x="157" y="184.5" fill="#331710" width="130" height="60" />
            </g>

            <path
                id="coffeeBottleGlass"
                fill="#FFFFFF"
                d="M177,134c-2.761,0-6.333,2.083-4.25,6.5L175,145c2.833,5,5.239,5,8,5h68v-16H177z
         M248,146h-65c-2.213,0-2.824,0-4.47-2.884l-2.202-4.405c-0.105-0.224-0.155-0.395-0.18-0.51C176.334,138.105,176.66,138,177,138
        h71V146z M246,156h-63c-4.971,0-9,4.029-9,9v39c0,4.971,4.029,9,9,9h63c4.971,0,9-4.029,9-9v-39C255,160.029,250.971,156,246,156z
         M251,204c0,2.757-2.243,5-5,5h-63c-2.757,0-5-2.243-5-5v-39c0-2.757,2.243-5,5-5h63c2.757,0,5,2.243,5,5V204z" />
            <path
                id="coffeeBottleShine"
                opacity="0.7"
                fill="#FFFFFF"
                d="M189,202c-3.313,0-6-2.687-6-6v-27c0-3.313,2.687-6,6-6s6,2.687,6,6
        v27C195,199.313,192.313,202,189,202z" />
            <path
                id="coffeeBottleHolder"
                fill="#1C1818"
                d="M279,137h-28v-4c0-1.657-1.343-3-3-3h-65c-1.657,0-3,1.343-3,3v7
        c0,1.657,1.343,3,3,3h65v7h-68c-1.657,0-3,1.343-3,3s1.343,3,3,3h71h2h6v44c0,0.553,0.447,1,1,1h21c0.553,0,1-0.447,1-1v-47v-3
        v-10C282,138.343,280.657,137,279,137z M277,197h-13c-0.553,0-1-0.447-1-1v-40h15v40C278,196.553,277.553,197,277,197z" />
        </g>
        <g id="coffeeMachineConsoleButton1">
            <path
                fill="#201918"
                d="M112,239c-1.103,0-2-0.897-2-2v-4c0-1.103,0.897-2,2-2h14c1.103,0,2,0.897,2,2v4c0,1.103-0.897,2-2,2H112z
        " />
            <path
                fill="#393131"
                d="M127,237c0,0.553-0.448,1-1,1h-12c-0.552,0-1-0.447-1-1v-4c0-0.553,0.448-1,1-1h12c0.552,0,1,0.447,1,1
        V237z" />
            <circle fill="#F01B24" cx="123" cy="235.001" r="2" />
        </g>
        <g id="coffeeMachineConsoleButton2">
            <path
                fill="#201918"
                d="M141,239c-1.103,0-2-0.897-2-2v-4c0-1.103,0.897-2,2-2h14c1.103,0,2,0.897,2,2v4c0,1.103-0.897,2-2,2H141z
        " />
            <path
                fill="#393131"
                d="M156,237c0,0.553-0.448,1-1,1h-12c-0.552,0-1-0.447-1-1v-4c0-0.553,0.448-1,1-1h12c0.552,0,1,0.447,1,1
        V237z" />
            <circle fill="#F01B24" cx="152" cy="235.001" r="2" />
        </g>
        <path id="coffeeMachineConsoleEarL" fill="#1C1818" d="M95,247h-4c-4.418,0-8-3.582-8-8v-9c0-4.418,3.582-8,8-8h4V247z" />
        <path
            id="coffeeMachineConsoleFootL"
            fill="#1C1818"
            d="M108,247v1.399c0,1.768-1.233,3.601-3,3.601h-3.6
      c-1.767,0-3.4-1.833-3.4-3.601V247H108z" />
        <path
            id="coffeeMachineConsoleFootR"
            fill="#1C1818"
            d="M266,247v1.399c0,1.768-1.232,3.601-3,3.601h-3.6
      c-1.768,0-3.4-1.833-3.4-3.601V247H266z" />
        <path id="coffeeMachineConsoleEarR" fill="#1C1818" d="M271,222h4c4.418,0,8,3.582,8,8v9c0,4.418-3.582,8-8,8h-4V222z" />
    </g>

    <g id="cupOfCoffee">
        <path
            fill="#AA9A8A"
            d="M230,199.334c0-4.051-3.283-7.334-7.333-7.334h-41.334c-4.05,0-7.333,3.283-7.333,7.334v0.332
      c0,4.051,3.283,7.334,7.333,7.334h41.334c4.05,0,7.333-3.283,7.333-7.334V199.334z" />
        <path
            fill="#E7EBEB"
            d="M235.084,144.167c-3.379,0-6.507,1.271-9.084,3.424V145v-4v-5c0-1.657-1.343-3-3-3h-5h-34h-5
      c-1.657,0-3,1.343-3,3v5v4v44c0,4.418,3.582,8,8,8h34c4.418,0,8-3.582,8-8v-10.092c2.577,2.153,5.705,3.425,9.084,3.425
      c8.79,0,15.916-8.544,15.916-19.083C251,152.71,243.874,144.167,235.084,144.167z M235.084,173.069c-4.524,0-8.19-4.396-8.19-9.819
      c0-5.424,3.666-9.819,8.19-9.819c4.523,0,8.189,4.396,8.189,9.819C243.273,168.673,239.607,173.069,235.084,173.069z" />
        <g id="coffeeSymbol_1_">
            <path
                id="coffeeSymbo2_1_"
                fill="#382113"
                d="M193.248,172.189c-2.552-2.522-1.043-7.731,3.186-11.755
        c4.027-4.231,9.222-5.726,11.758-3.189c0.953,0.953-2.571,3.929-6.87,8.078C197.173,169.619,194.2,173.143,193.248,172.189z" />
            <path
                id="coffeeSymbol1_1_"
                fill="#382113"
                d="M210.753,159.807c2.552,2.523,1.043,7.731-3.187,11.755
        c-4.026,4.231-9.22,5.726-11.758,3.189c-0.953-0.953,2.571-3.929,6.869-8.076C206.827,162.376,209.8,158.854,210.753,159.807z" />
        </g>
        <path
            opacity="0.3"
            fill="#FFFFFF"
            d="M194,181.167c0,3.773-3.06,6.833-6.833,6.833h-0.333c-3.774,0-6.833-3.06-6.833-6.833
      v-20.334c0-3.773,3.06-6.833,6.833-6.833h0.333c3.774,0,6.833,3.06,6.833,6.833V181.167z M194,143.833
      c0-3.773-3.06-6.833-6.833-6.833h-0.333c-3.774,0-6.833,3.06-6.833,6.833v0.334c0,3.773,3.06,6.833,6.833,6.833h0.333
      c3.774,0,6.833-3.06,6.833-6.833V143.833z" />
    </g>
</svg>
<h1>
    {{ message }}
    <div class="dots">
        <span class="dot z"></span><span class="dot f"></span><span class="dot s"></span><span class="dot t"><span class="dot l"></span></span>
    </div>
</h1>
